import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import PostIndexBody from "../components/post-index-body"
import Pagination from "../components/pagination"
import style from "./artindex.module.css"

const SubjectIndex = ({ data, pageContext, location }) => {
  const { subject } = pageContext
  const { totalCount } = data.allMarkdownRemark
  const posts = data.allMarkdownRemark.edges

  const subjectHeader = `${totalCount} post${
    totalCount === 1 ? "" : "s"
  } filed under "${subject}"`

  return (
    <Layout location={location}>
      <section class={style.articlelist}>
        <h2>{subjectHeader}</h2>
        <ul>
          {posts.map(({ node }, index) => (
            <PostIndexBody post={node} index={index} />
          ))}
        </ul>
      </section>
      <Pagination pageContext={pageContext} />
    </Layout>
  )
}

export default SubjectIndex

export const query = graphql`
  query($subject: String, $skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      filter: { frontmatter: { subject: { in: [$subject] } } }
      sort: { fields: [frontmatter___date], order: DESC }
      skip: $skip
      limit: $limit
    ) {
      totalCount
      edges {
        node {
          excerpt
          id
          frontmatter {
            title
            date
            subject
            author
            featimg {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
